import Vue from "vue";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import uuid from "@utils/uuid";
const { fetchMenu } = requests;

const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export default Vue.extend({
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    headquater: {
      type: String,
      default: () => ""
    }
  },
  components: {
    RestaurantHeader: () => import("@components/Headers/RestaurantHeader"),
    Order: () => import("@components/steppers/Order")
  },
  filters: {
    filterByCategory(list, category) {
      return list.filter(item => item.categories[category]);
    },
    isPopulate(dishes) {
      return Object.keys(dishes).length === 0;
    }
  },
  data: () => ({
    menus: [],
    readyToOrder: false,
    fetchingDish: true,
    error: false,
    stepperId: uuid(),
    menuMode: "user",
    drinks: "9c4e239e-6e8e-4447-937e-53a234d038e8",
    dessert: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
    main: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
    step: 1,
    dayweek: weekDays[new Date().getDay() - 1],
    notifications: false,
    sound: true,
    widgets: false
  }),
  watch: {
    dialog(open) {
      if (open) {
        this.stepperId = uuid();
        this.getMenu();
      }
    },
    step() {
      this.getMenu();
    }
  },
  methods: {
    getCurrentCategory() {
      const { step } = this;
      const onMain = step === 1;
      const onDrinks = step === 2;
      const onDessert = step === 3;
      if (onMain) return this.main;
      if (onDrinks) return this.drinks;
      if (onDessert) return this.dessert;
    },
    async getMenu() {
      const categories = this.getCurrentCategory();
      this.fetchingDish = true;
      const { ok, result: dishes } = await surePromise(fetchMenu(this.headquater, { categories }));
      this.fetchingDish = false;
      if (ok) {
        this.menus = dishes;
        return;
      }
      this.error = true;
    }
  },
  computed: {
    lang() {
      const { lang = "ES" } = window.user || {};
      return lang;
    }
  }
});
